import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PopularArea from "../MainPage/PopularArea/PopularArea";
import { capitalizeAndFormat } from "../functions/generalFun";
import OfferComponentN from "./OfferComponentN";
import { useSelector, useDispatch } from "react-redux";

import { fetchOffer } from "../../Store/offerSlice";
import FooterN from "../Common/Footer/FooterN";
import Header from "../Common/Navbar/Header";
import "./listpagen.css";
import OtherListCard from "./listCard/OtherListCard";
import { Helmet } from "react-helmet";


const ListPage = ({ title, subtitle, name }) => {
  const params = useParams();

  const dispatch = useDispatch();

  const slug = params?.slug;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const offerState = useSelector((state) => state.offers);
  const [OfferName, setOfferName] = useState("Best Offers");


  const [offerData, setOfferData] = useState({ loading: true, data: [] });

  const getOfferData = async (pageCount) => {
    const requestOption = {
      method: "GET",
      header: { "Content-Type": "application/json" },
    };

    await fetch(
      `https://trakky.in:8000/salons/salon-city-offer/${params.slug}/`,
      requestOption
    )
      .then((res) => res.json())
      .then((data) => {
        setOfferData({ loading: false, data: data[0]?.salons });
        setOfferName(data[0]?.offer_name);
        // !data?.next ? setHasMore(false) : setHasMore(true);
      })
      .catch((err) => {
        setOfferData({ loading: false, data: [] });
      });

    setOfferData((prev) => {
      return { loading: false, data: [...prev.data] };
    });
  };

  useEffect(() => {
    getOfferData(page);
  }, [slug]);

  useEffect(() => {
    if (
      offerState?.city == null ||
      offerState.city.toLowerCase() !=
        capitalizeAndFormat(params.city).toLowerCase()
    ) {
      dispatch(fetchOffer({ city: params.city }));
    }
  }, [params.city]);

  return (
    <>

      <Helmet>
        <title>Checkout exclusive {OfferName} in {capitalizeAndFormat(params?.city)} only on trakky.</title>
        <meta
          name="description"
          content={`Book now ${OfferName} salon in ${capitalizeAndFormat(params?.city)}. Book trusted salon services with trakky near your location with best exclusive ${OfferName} available on trakky.`}
        />
      </Helmet>

      <div className="N-list-page-container relative">
        <div className="N-list-page-background-color"></div>
        <Header />
        <OfferComponentN title={"Grab The Best Offers"} />
        <div className="N-listpage-heading">
          <h1>List of  {OfferName} in {capitalizeAndFormat(params?.city)} </h1>
        </div>

        {offerData?.data.length > 0 && (
          <div className="N-lp-card-listing-container">
            {offerData?.data.map((item, index) => {
              return <OtherListCard key={index} data={item} />;
            })}
          </div>
        )}

        {hasMore &&
          (!offerData.loading ? (
            <div className="N-lp-load-more">
              <button
                onClick={() => {
                  getOfferData(page + 1);
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <div className="N-lp-load-more">
              <div className="N-lp-loader"></div>
            </div>
          ))}

        <PopularArea />
        <FooterN city={params?.city || "ahmedabad"} />
      </div>
    </>
  );
};

export default ListPage;
