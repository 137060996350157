import React, { useState, useEffect } from 'react';
import "./Report.css";

const Report = () => {
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [salonList, setSalonList] = useState([]);
  const [selectedSalon, setSelectedSalon] = useState('');
  const [experience, setExperience] = useState('');

  useEffect(() => {
    // Fetch city list from your API
    fetch('https://trakky.in:8000/salons/city/')
      .then(response => response.json())
      .then(data => setCityList(data.payload))
      .catch(error => console.error('Error fetching city list:', error));
  }, []);

  useEffect(() => {
    // Fetch salon list based on the selected city from your API
    if (selectedCity) {
      fetch(`https://trakky.in:8000/salons/?city=${selectedCity}`)
        .then(response => response.json())
        .then(data => setSalonList(data.results))
        .catch(error => console.error('Error fetching salon list:', error));
    }
  }, [selectedCity]);
  
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleSalonChange = (event) => {
    setSelectedSalon(event.target.value);
  };

  const handleExperienceChange = (event) => {
    setExperience(event.target.value);
  };

  const handleSubmit = () => {
    // Handle the submission logic, e.g., send a request to the server
    // Add further actions as needed
  };

  return (
    <div className="report-container">
      <h1 className="edit-P-C-heading report-heading">Report Salon</h1>
      <form className="report-form" action="">
      <div className="PP-form-item">
        <select className='select-city' id="citySelect" style={{color:"black"}} value={selectedCity} onChange={handleCityChange}>
          <option value="" >Select a City</option>
          {cityList.map(city => (
            <option key={city.id} value={city.name}>
              {city.name}
            </option>
          ))}
        </select>
        <label htmlFor="experienceTextarea">City:</label>
</div>
        
        <div className="PP-form-item">
        <select className='select-salon' id="salonSelect" style={{color:"black"}} value={selectedSalon} onChange={handleSalonChange}>
          <option value="">Select a Salon</option>
          {salonList.map(salon  => (
            <option key={salon.id} value={salon.name}>
              {salon.name}
            </option>
          ))}
        </select>
        <label htmlFor="experienceTextarea">Salon:</label>
        </div>
        {/* <label htmlFor="experienceTextarea">Write about your experience:</label> */}
        <div className="report-textarea">
        <textarea className="experience-textarea" id="experienceTextarea" value={experience} onChange={handleExperienceChange}  />
        <label htmlFor="experience-textarea"> Share your thoughts...</label>
        </div>
        <button className='report-btn' onClick={handleSubmit}>SUBMIT</button>
      </form>
    </div>
  );
};

export default Report;
