import React from 'react';
import "./ReferalPage.css";
import TrakkyBox from "../trakky-box.png"
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ReferralPage = () => {
    const referralCode = '8469664233';
    
    const handleCopyCode = () => {
        navigator.clipboard.writeText(referralCode);
        toast.success('Referral code copied to clipboard!', {
            position: toast.POSITION.BOTTOM_RIGHT
        });
        alert('Referral code copied to clipboard!');
    };

    const referralCodeSection = (
        <div className="referral-code-section" onClick={handleCopyCode}>
            <div className="code-section">
                <p>Copy Your Code</p>
                <span className='code'> {referralCode} </span>
            </div>
            <span className='copy-icon'>
                <ContentCopyIcon fontSize="large" />
            </span>
        </div>
    );

    return (
        <div className="referral-container">
            <h1>Referral</h1>
            <div className="referral-details">
                <div className="count-earnings">
                    <div className="referral-count">
                        <p className='number'>0</p>
                        <p>Referrals</p>
                    </div>
                    <div className="referral-earnings">
                        <p className='number'>₹150</p>
                        <p>Coins earned</p>
                    </div>    
                </div>
                {window.innerWidth >768 ? referralCodeSection : null}
            </div>
            <p style={{color:"#838383"}}>Refer a friend and get rewards</p>
            <img src={TrakkyBox} alt="" />
            <div className="referral-share"> 
                {window.innerWidth <= 768 ? referralCodeSection : null}
                <button className='share-friends'>Invite Friends</button>
            </div>
        </div>
    );
};

export default ReferralPage;
