import React, { useEffect, useState, useContext, useRef } from "react";
import heart_svg from "../../Assets/images/icons/heart_2.svg";
import Score_svg from "../../Assets/images/icons/score_svg.svg";
import { Link } from "react-router-dom";
import { FcLike } from "react-icons/fc";
import AuthContext from "../../context/Auth";
import { capitalizeAndFormat } from "../functions/generalFun";

const BestSalons = ({ cities }) => {
  const [activeCity, setActiveCity] = useState();
  const [salonData, setSalonData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { userFavorites } = useContext(AuthContext);
  const controllerRef = useRef();

  const getSalons = async () => {
    setLoading(true);

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    try {
      const response = await fetch(
        `https://trakky.in:8000/salons/filter/?city=${activeCity}&page=1&verified=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          signal,
        }
      );
      const data = await response.json();

      if (response.ok) {
        setSalonData(data?.results);
        setLoading(false);
      } else {
        console.log("error");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setActiveCity(cities[0]?.name);
  }, [cities]);

  useEffect(() => {
    if (activeCity) {
      getSalons();
    }
  }, [activeCity]);

  
  const getOfferFormat = (custom_offer_tag, offer_tag) => {
    let offerFormat = ""; // Variable to store the offer format
    let offerContent = ""; // Variable to store the content after the first word

    if (custom_offer_tag === null || custom_offer_tag === undefined) {
      offerContent = offer_tag;
      return { offerFormat, offerContent };
    }
    // Splitting the offer tag by space
    const offerParts = custom_offer_tag?.split(" ");
    if (offerParts[0] === "Get") {
      if (offerParts[1].endsWith("%")) {
        offerFormat = "percentage"; // Format: Get ${any_%} % off on ${servicename}
        offerContent = custom_offer_tag;
      } else if (offerParts[offerParts.length - 1] === "off") {
        offerFormat = "fixed-amount"; // Format: Get ₹${any_amount} off
        offerContent = custom_offer_tag;
      } else {
        offerFormat = "fixed-amount-service"; // Format: Get ₹${any_amount} off on ${servicename}
        offerContent = custom_offer_tag;
      }
    } else {
      offerFormat = "service-price"; // Format: ${servicename} at ₹${any_amount}
      offerContent = custom_offer_tag;
    }
    return { offerFormat, offerContent };
  };

  return (
    <div className="pt-5 lg:pt-10">
      <div className="mx-4 flex justify-between md:mx-10">
        <h2 className="text-xl font-semibold">Best salons in {capitalizeAndFormat(activeCity)?.toLowerCase()}</h2>
      </div>
      <div className="mt-3 flex overflow-x-scroll gap-3 ml-4 md:ml-10 snap-x snap-proximity">
        {cities?.map((item, index) => (
          <button
            className={`py-3 px-5 rounded-lg text-sm font-semibold snap-start last:mr-4 md:last:mr-10 ${
              item?.name.toLowerCase() == activeCity?.toLowerCase()
                ? "text-white bg-gradient-to-r from-[#9B6DFC] to-[#5732CC]"
                : "border border-slate-800 border-solid"
            }`}
            onClick={() => setActiveCity(item?.name)}
            key={index}
          >
            {item?.name}
          </button>
        ))}
      </div>
      {!loading ? (
        salonData?.length > 0 ? (
          <div className="N-Profile-page-suggested-salons snap-x snap-proximity ml-[5px] md:ml-[30px] md:mt-2">
            {salonData?.map((item, index) => 
              {
                const { offerFormat, offerContent } = getOfferFormat(
                  item?.custom_offer_tag,
                  item?.offer_tag
                );

                return (
              <Link
                to={`/${encodeURIComponent(item?.city)}/${encodeURIComponent(
                  item?.area
                )}/salons/${encodeURIComponent(item?.slug)}`}
                className="N-Profile-page-saggested-salon-card snap-start first:!ml-0 last:!mr-4 md:last:!mr-10"
                key={index}
              >
                <div className="N-Profile-page-suggested-salon-card-img shadow rounded-2xl drop-shadow">
                  <img src={item?.main_image} alt="" />
                  {!userFavorites?.some((item1) => {
                    let res = item1?.salon === item?.id;
                    return res;
                  }) ? (
                    <div className=" absolute top-2 right-2 z-10 h-6 w-6">
                      <img
                        src={heart_svg}
                        alt=""
                        className="h-full w-full !object-contain !rounded-none"
                      />
                    </div>
                  ) : (
                    <div className=" absolute top-[5px] right-[6px] z-10 h-7 w-7">
                      <FcLike className="w-full h-full" />
                    </div>
                  )}
                  {item?.premium && (
                    <div className="premium_div-x absolute">
                      <div className="premium-text">Premium</div>
                    </div>
                  )}
                  <div className="offer-tag-p-s-s">
                  <span>
                      {offerFormat === "fixed-amount" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span className="custom-offer-tag-text1">
                            {offerContent.split(" ")[0].toUpperCase()}
                          </span>
                          <span className="custom-offer-tag-text2">
                            {" "}
                            {offerContent
                              .split(" ")
                              .slice(1)
                              .join(" ")
                              .toUpperCase()}
                          </span>
                        </div>
                      ) : offerFormat === "percentage" ||
                        offerFormat === "fixed-amount-service" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span className="custom-offer-tag-text1">
                            {offerContent.split(" ")[0].toUpperCase()}
                          </span>
                          <span className="custom-offer-tag-text2">
                            {" "}
                            {offerContent.split(" ")[1].toUpperCase()} OFF
                          </span>
                          <span className="custom-offer-tag-text3 line-clamp-1">
                            {" "}
                            ON{" "}
                            {offerContent
                              .split(" ")
                              .slice(4)
                              .join(" ")
                              .toUpperCase()}
                          </span>
                        </div>
                      ) : offerFormat === "service-price" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span className="custom-offer-tag-text1 line-clamp-1">
                            {offerContent
                              .split(" ")
                              .slice(0, -2)
                              .join(" ")
                              .toUpperCase()}
                          </span>{" "}
                          <span className="custom-offer-tag-text2">
                            {offerContent.split(" ").slice(-2)[0].toUpperCase()}{" "}
                            {offerContent.split(" ").slice(-1)[0].toUpperCase()}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                </div>
                <div className="N-Profile-page-suggested-salon-card-content">
                  <h2>{item?.name}</h2>
                  <p className="N-P-S-S-score">
                    <img src={Score_svg} alt="" />
                    <span>4.5</span>
                  </p>
                  <p className="N-P-S-S-addr">
                    {item?.area} , {item?.city}
                  </p>
                </div>
              </Link>
            )
            }
          )}
          </div>
        ) : (
          <div className="flex justify-center items-center h-28">
            <h2 className=" text-lg font-semibold">No Salons Found</h2>
          </div>
        )
      ) : (
        <div className="flex justify-center items-center h-60">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default BestSalons;
