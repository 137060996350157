import React, { useEffect, useState } from "react";
import ListCard from "./listCard/ListCard";
import Hero from "../SalonPage/Hero/Hero";
import "./listpage.css";
import { Link, useParams } from "react-router-dom";
import PopularArea from "../MainPage/PopularArea/PopularArea";
import Footer from "../Common/Footer/Footer";
import Slider from "../Common/Slider/Slider";
import { capitalizeAndFormat } from "../functions/generalFun";
import {
  getSalonsNearYou,
  getTopRatedSalons,
  getBridalSalons,
  getUnisexSalons,
  getKidsSalons,
  getFemaleBeautySalons,
  getAcademySalons,
  getMakeupSalons,
  getAllAreaSalons,
} from "./pageapi";
import FooterN from "../Common/Footer/FooterN";
import OtherListCard from "./listCard/OtherListCard";
import Header from "../Common/Navbar/Header";
import OfferComponentN from "./OfferComponentN";
import { Helmet } from "react-helmet";

const AreaListPage = ({ title, subtitle, name }) => {
  const params = useParams();

  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const path = window.location?.pathname?.split("/")[2];
  const [city, setCity] = useState(params?.city);
  const [area, setArea] = useState(params?.area);

  const NavOptions = [
    {
      tag: "All",
      link: "salons",
    },
    {
      tag: "Top Rated Salons",
      link: "topratedsalons",
    },
    {
      tag: "Bridal Salons",
      link: "bridalsalons",
    },
    {
      tag: "Unisex Salons",
      link: "unisexsalons",
    },
    {
      tag: "Kids Salons",
      link: "kidsspecialsalons",
    },
    {
      tag: "Female Beauty Parlour",
      link: "femalebeautyparlour",
    },
    {
      tag: "Academy Salons",
      link: "academysalons",
    },
    {
      tag: "Makeup Salons",
      link: "makeupsalons",
    },
  ];

  const handleTopRatedSalons = async () => {
    setIsDataLoading(true);
    let data = await getTopRatedSalons(
      city,
      area,
      page
    );
    setListData((prevValue) => [...prevValue, ...data.results]);
    // data.next && setHasMore(true);
    if (data?.next) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setIsDataLoading(false);
  };

  const handleBridalSalons = async () => {
    setIsDataLoading(true);
    let data = await getBridalSalons(
      city,
      area,
      page
    );
    setListData((prevValue) => [...prevValue, ...data.results]);
    // data.next && setHasMore(true);
    if (data?.next) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setIsDataLoading(false);
  };

  const handleUnisexSalons = async () => {
    setIsDataLoading(true);
    let data = await getUnisexSalons(
      city,
      area,
      page
    );
    setListData((prevValue) => [...prevValue, ...data.results]);
    // !data.next && setHasMore(false);
    if (data?.next) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setIsDataLoading(false);
  };

  const handleKidsSalons = async () => {
    setIsDataLoading(true);
    let data = await getKidsSalons(
      city,
      area,
      page
    );
    setListData((prevValue) => [...prevValue, ...data.results]);
    // !data.next && setHasMore(false);
    if (data?.next) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setIsDataLoading(false);
  };

  const handleFemaleBeautySalons = async () => {
    setIsDataLoading(true);
    let data = await getFemaleBeautySalons(
      city,
      area,
      page
    );
    setListData((prevValue) => [...prevValue, ...data.results]);
    if (data?.next) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setIsDataLoading(false);
  };

  const handleAcademySalons = async () => {
    setIsDataLoading(true);
    let data = await getAcademySalons(
      city,
      area,
      page
    );
    setListData((prevValue) => [...prevValue, ...data.results]);
    // !data.next && setHasMore(false);
    if (data?.next) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setIsDataLoading(false);
  };

  const handleMakeupSalons = async () => {
    setIsDataLoading(true);
    let data = await getMakeupSalons(
      city,
      area,
      page
    );
    setListData((prevValue) => [...prevValue, ...data.results]);
    // !data.next && setHasMore(false);
    if (data?.next) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }

    setIsDataLoading(false);
  };

  const handleSalonAreaWise = async () => {
    setIsDataLoading(true);
    let data = await getAllAreaSalons(
      city,
      area,
      page
    );
    setListData((prevValue) => [...prevValue, ...data.results]);
    // !data.next && setHasMore(false);
    if (data?.next) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    if (page == 1) {
      setListData([]);
    }

    name == "topRatedSalons" && handleTopRatedSalons();
    name == "bridalSalons" && handleBridalSalons();
    name == "unisexSalons" && handleUnisexSalons();
    name == "kidsSpecialSalons" && handleKidsSalons();
    name == "femaleBeautyParlour" && handleFemaleBeautySalons();
    name == "academySalons" && handleAcademySalons();
    name == "makeupSalons" && handleMakeupSalons();
    name == "areaPage" && handleSalonAreaWise();
  }, [page, city, area, path]);

  useEffect(() => {
    setArea(params?.area);
    setListData([]);
  }, [params?.area]);
  useEffect(() => {
    setCity(params?.city);
  }, [params?.city]);

  const getMetaTitleDetails = () => {
    if (name === "areaPage") {
      return `Checkout list of salons in ${capitalizeAndFormat(
        params?.city
      )} with trakky`;
    } else if (
      name === "topRatedSalons" ||
      name === "bridalSalons" ||
      name === "unisexSalons" ||
      name === "kidsSpecialSalons" ||
      name === "femaleBeautyParlour"
    ) {
      return `Checkout list of ${subtitle} salons in ${capitalizeAndFormat(
        params?.area
      )}, ${capitalizeAndFormat(params?.city)} and book services now.`;
    } else if (name === "academySalons") {
      return `Checkout list of salon academy in ${capitalizeAndFormat(
        params?.area
      )}, ${capitalizeAndFormat(params?.city)} and book now.`;
    } else if (name === "makeupSalons") {
      return `Checkout list of makeup salons in ${capitalizeAndFormat(
        params?.area
      )}, ${capitalizeAndFormat(params?.city)} and book makeup services now.`;
    } else {
      return "";
    }
  };
  

  const getMetaDescDetails = () => {
    if (name === "areaPage") {
      return `Book trusted & best salon services in best salons of ${capitalizeAndFormat(params?.area)} with best exclusive offers available on trakky.`
    }
    else if (name === "topRatedSalons") {
      return `Are you looking for top rated salon in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}? your search results are here! Book salon services in top rated salons of ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}.`
    }
    else if (name === "bridalSalons") {
      return `Are you looking for bridal salon in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}? Checkout best bridal salons here, Book bridal services in top bridal salons of ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}.`
    }
    else if (name === "academySalons") {
      return `Are you looking for best salon academy in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}? Checkout best salon academy here, Select salon academy course in best salon academy of ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} with trakky.`
  } else if (name === "makeupSalons") {
    return `Are you looking for makeup salon in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}? Checkout best makeup salons here, Book makeup services in top salons of ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}.`

  } else if (name === "unisexSalons") {
    return `Are you looking for best unisex salon in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}? Checkout best unisex salons here, Book salon services in best unisex salons of ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}.`
  } else if (name === "kidsSpecialSalons") {
    return `Are you looking for best kids salon in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}? Checkout best kids salons here, Book salon services in best kids salons of ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}.`
  } else if (name === "femaleBeautyParlour") {
    return `Are you looking for female beauty parlours in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}? Checkout female beauty parlours here, Book female beauty parlours services in female beauty parlours of ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}.`
  } else {
    return "";
  }
}
  return (
    <>
      <Helmet>

        <title>{getMetaTitleDetails()}</title>
        <meta name="description" content={getMetaDescDetails()} />
      
      </Helmet>
      <div className="N-list-page-container relative">
        <div className="N-list-page-background-color"></div>
        <Header />
        <div className="L-list-page-option-n-filter">
          {city &&
            area &&
            NavOptions.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    item.link == path
                      ? "!bg-[#512DC8] !text-white text-sm sort-box "
                      : " sort-box"
                  }
                >
                  <Link
                    to={`/${encodeURIComponent(city)}/${
                      item.link
                    }/${encodeURIComponent(area)}`}
                    className=" text-inherit"
                  >
                    {item.tag}
                  </Link>
                </div>
              );
            })}
        </div>

        <OfferComponentN title={"Grab The Best Offers"} area={params?.area} />

        {
          <div className="N-listpage-heading">
            <h1>
              {name === "areaPage"
                ? `List of Salons In ${capitalizeAndFormat(area)}`
                : `List of ${subtitle} salons in ${capitalizeAndFormat(
                    params?.area
                  )}, ${capitalizeAndFormat(params?.city)}`}
            </h1>
          </div>
        }
        <div className="N-lp-card-listing-container">
          {isDataLoading ? (
            <div className="N-lp-load-more">
              <div className="N-lp-loader"></div>
            </div>
          ) : listData?.length > 0 ? (
            listData?.map((item, index) => {
              return <OtherListCard key={index} data={item} />;
            })
          ) : (
            <div className=" mx-auto h-20 flex items-center font-semibold">
              No salon found
            </div>
          )}
        </div>
        {hasMore &&
          (!isDataLoading ? (
            <div className="lp-load-more">
              <button
                onClick={() => {
                  setPage((prevValue) => prevValue + 1);
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <div className="lp-load-more">
              <div className="lp-loader"></div>
            </div>
          ))}
        <PopularArea />
        <FooterN city={params?.city || "ahmedabad"} />
      </div>
    </>
  );
};

export default AreaListPage;