
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSalonsNearYou } from "../Components/listPage/pageapi";

export const fetchNearBySalons = createAsyncThunk(
    "nearbysalons/fetchNearBySalons",
    async ({ latitude , longitude , page }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getSalonsNearYou(latitude , longitude , page);
            response.latitude = latitude;
            response.longitude = longitude;
            response.page = page;
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const nearbysalons = createSlice({
    name: "nearbysalons",
    initialState: {
        data: [],
        loading: false,
        error: null,
        page: 1,
        isNextPage: false,
        latitude: 0,
        longitude: 0,
        preferableCity: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNearBySalons.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNearBySalons.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.page === 1) {
                    state.data = action.payload.results;
                }else{
                    state.data = [...state.data, ...action.payload.results]
                }
                state.page = action.payload.page + 1;
                state.isNextPage = action.payload.next !== null;
                state.latitude = action.payload.latitude;
                state.longitude = action.payload.longitude;
                state.preferableCity = action.payload?.results?.[0]?.city;
            })
            .addCase(fetchNearBySalons.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
    
});

export const nearBySalons = nearbysalons.reducer;


