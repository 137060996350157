import React from 'react';
import { TextField, Button } from '@mui/material';

const FeedbackForm = () => {
  return (
    <div className="mt-2 max-w-lg mx-auto p-6 border border-gray-300 rounded-lg bg-white shadow-md">
      {/* Header */}
      <div className="w-full flex justify-center items-center mb-6">
        <h1 className="text-2xl font-bold bg-yellow-300 text-black p-3 rounded shadow-sm">Feedback</h1>
      </div>

      {/* Form */}
      <form className="space-y-4">
        {/* First Row: Name and Phone in a single row on larger screens */}
        <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4">
          <div className="w-full">
            <TextField 
              label="Name"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="w-full">
            <TextField 
              label="Phone no."
              variant="outlined"
              fullWidth
            />
          </div>
        </div>

        {/* Second Row: Email */}
        <div className="w-full">
          <TextField 
            label="Email"
            variant="outlined"
            fullWidth
          />
        </div>

        {/* Message Field */}
        <div className="w-full">
          <TextField 
            label="Message..."
            variant="outlined"
            multiline
            rows={4}
            fullWidth
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <Button 
            variant="contained" 
            className="w-full md:w-1/3"
            style={{background:"linear-gradient(90deg, #9E70FF 0%, #9E70FF 0.01%, #522EC9 100%)"}}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
