// ReschedulePage.js
import React from 'react';

const ReschedulePage = () => {
  // Add state for the new date and time
  const [newDate, setNewDate] = React.useState(new Date());
  const [newTime, setNewTime] = React.useState(new Date());

  return (
    <div className="reschedule-container">
      <h1>Reschedule Booking</h1>
     
    </div>
  );
};

export default ReschedulePage;
