import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./mainhome.css";
import flagImg from "../../Assets/images/other/flag.png";
import arrowSvg from "../../Assets/images/icons/right_arrow_N.svg";
import Footer from "../Common/Footer/Footer";
import FooterN from "../Common/Footer/FooterN";
import { Link } from "react-router-dom";
import TopAreaOfCityDashboard from "../Common/toparea/TopAreaOfCityDashboard";
import "./Main.css";
import WhatsAppButton from "../Common/whatsapp/WhatsappButton";
import FAQ from "../Faq/FAQ";
import BestSalons from "./BestSalons";
import Header from "../Common/Navbar/Header";
import HeroOfferPng from "../../Assets/images/icons/hero_offer.png";
import Note_icon from "../../Assets/images/icons/note_icon.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import PopularLocations from "./PopularLocations";

import right_arrow from "../../Assets/images/icons/right_arrow.svg";

import BangaloreImg from "../../Assets/images/city/bangalore.svg";
import AhmedabadImg from "../../Assets/images/city/ahmedabad.svg";
import Gandhinagar from "../../Assets/images/city/gandhinagar.svg";
import MumbaiImg from "../../Assets/images/city/mumbai.svg";
import DelhiImg from "../../Assets/images/city/delhi.svg";
import loading_gif from "../../Assets/images/logos/Trakky website graphics.gif";
const MainHome = () => {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(true)
  const [popularcity, setPopularCity] = React.useState([]);
  const [expandedCities, setExpandedCities] = useState({});
  const [displayNo, setDisplayNo] = useState(6);
  const [CategoriesData, setCategoriesData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  // const [popularCityName, setPopularCityName] = useState([]);
  // const [topAreas, setTopAreas] = useState([]);
  const [city, setCity] = useState([]);
  const [featureThisWeekData, setFeatureThisWeekData] = useState([]);
  const [heroNationalOffer, setHeroNationalOffer] = useState([]);


   
  useEffect(()=>{
    const timer = setTimeout(() => {
      setLoading(false)
    }, 800);
    return () => clearTimeout(timer);
  },[])
  
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 200);
    return () => clearTimeout(timer);
  }, []);


  // const getAreas = async () => {
  //   await fetch(`https://trakky.in:8000/salons/areaimage/`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setPopularCity(data);

  //       data?.map((item) => {
  //         setPopularCityName((prev) => [...prev, item.city]);
  //       });
  //     });
  // };




  const getOfferFormat = (custom_offer_tag, offer_tag) => {
    let offerFormat = ""; // Variable to store the offer format
    let offerContent = ""; // Variable to store the content after the first word

    if (custom_offer_tag === null || custom_offer_tag === undefined) {
      offerContent = offer_tag;
      return { offerFormat, offerContent };
    }
    // Splitting the offer tag by space
    const offerParts = custom_offer_tag?.split(" ");
    if (offerParts[0] === "Get") {
      if (offerParts[1].endsWith("%")) {
        offerFormat = "percentage"; // Format: Get ${any_%} % off on ${servicename}
        offerContent = custom_offer_tag;
      } else if (offerParts[offerParts.length - 1] === "off") {
        offerFormat = "fixed-amount"; // Format: Get ₹${any_amount} off
        offerContent = custom_offer_tag;
      } else {
        offerFormat = "fixed-amount-service"; // Format: Get ₹${any_amount} off on ${servicename}
        offerContent = custom_offer_tag;
      }
    } else {
      offerFormat = "service-price"; // Format: ${servicename} at ₹${any_amount}
      offerContent = custom_offer_tag;
    }
    return { offerFormat, offerContent };
  };

  const getFeatureThisWeek = async () => {
    let url = `https://trakky.in:8000/salons/feature-this-week/`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      setFeatureThisWeekData(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  // useEffect(() => {
  //   const areasByCity = popularcity.map((city) => {
  //     const areas = city.areas;
  //     return { city: city.city, areas };
  //   });
  //   setTopAreas(areasByCity);
  // }, [popularcity]);

  const handleShowMoreArea = (cityId) => {
    setExpandedCities((prevExpandedCities) => ({
      ...prevExpandedCities,
      [cityId]: true,
    }));
  };

  useEffect(() => {
    if (window.innerWidth < 540) {
      setDisplayNo(3);
    }
    // getAreas();
    getCategories();
    getOffer();
    getFeatureThisWeek();
  }, []);

  // Getting Category Data
  const getCategories = () => {
    const requestOption = {
      method: "GET",
      header: {
        "Content-Type": "application/json",
      },
    };
    fetch("https://trakky.in:8000/salons/national-category/", requestOption)
      .then((res) => res.json())
      .then((data) => {
        setCategoriesData(data);
      })
      .catch((err) => console.log(err));
  };

  const getOffer = () => {
    const requestOption = {
      method: "GET",
      header: {
        "Content-Type": "application/json",
      },
    };
    fetch("https://trakky.in:8000/salons/national-offers/", requestOption)
      .then((res) => res.json())
      .then((data) => {
        setOffersData(data);
      })
      .catch((err) => console.log(err));
  };

  const handleScroll = () => {
    const startDiv = document.getElementById("start");
    startDiv.scrollIntoView({ behavior: "smooth" });
  };

  const getCities = () => {
    const requestOption = {
      method: "GET",
      header: {
        "Content-Type": "application/json",
      },
    };
    fetch("https://trakky.in:8000/salons/city/", requestOption)
      .then((res) => res.json())
      .then((data) => {
        setCity(data.payload);
      })
      .catch((err) => console.log(err));
  };

  const getHeroOffers = async (city) => {
    const requestOption = {
      method: "GET",
      header: {
        "Content-Type": "application/json",
      },
    };
    let url = `https://trakky.in:8000/salons/national-hero-offers/?is_national=true`;

    try {
      let res = await fetch(url, requestOption);
      let data = await res.json();
      setHeroNationalOffer(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getHeroOffers();
    getCities();
  }, []);

  return (
    loading ? <div className="w-full h-[100vh] flex justify-center items-center">
    <img src={loading_gif} className="h-[200px] w-[200px]" alt="" />
    </div> :
    <div className="">
      <WhatsAppButton />

      <div className="bg-[#CBC7F0] rounded-b-3xl">
        <Header />

        <div className="max-w-[100%]  mt-5 mx-[25px] md:max-w-[600px] md:mx-auto lg:max-w-[700px] clg:max-w-[800px]">
          <Swiper
            slidesPerView="1"
            navigation
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {heroNationalOffer?.map((item, index) => (
              <SwiperSlide className="">
                <div
                  className="h-auto w-full cursor-pointer"
                  onClick={() => {
                    if (
                      !item?.salon_slug ||
                      !item?.salon_city ||
                      !item?.salon_area
                    ) {
                      return;
                    }
                    navigate(
                      `/${encodeURIComponent(
                        item?.salon_city
                      )}/${encodeURIComponent(
                        item?.salon_area
                      )}/salons/${encodeURIComponent(item?.salon_slug)}`
                    );
                  }}
                >
                  <img
                    src={item?.image}
                    className="h-auto w-full object-contain rounded-lg"
                    alt=""
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="ml-4 mt-4 py-4 flex gap-5 overflow-x-scroll snap-x snap-mandatory md:ml-10">
          {CategoriesData?.map((category, index) => (
            <div
              className="flex flex-col gap-2 snap-start last:mr-4 cursor-pointer"
              onClick={handleScroll}
            >
              <div className=" bg-gray-200 h-28 w-28 rounded-full lg:h-32 lg:w-32">
                {category?.image && (
                  <img
                    src={category?.image}
                    alt={category?.title}
                    className="h-full w-full object-cover rounded-full shadow-sm drop-shadow-sm"
                  />
                )}
              </div>
              <div className=" line-clamp-2 font-semibold text-center">
                {category?.title}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className=" mx-4">
        <div className=" w-fit max-w-full border-y border-gray-200 my-4 text-[11px] text-gray-500 flex items-center gap-2 mx-auto py-2 lg:text-sm">
          <img src={Note_icon} alt="" className="h-6 w-6 object-contain" />
          Refresh your look for less with exclusive discounts on salon bookings!
        </div>
      </div>

      {/* {popularcity && popularcity.length > 0 && topAreas.length > 0 && (
        <TopAreaOfCityDashboard
          cityname={popularCityName.slice(0, 5)}
          areas={topAreas}
        />
      )} */}

      <div className="flex flex-col gap-2 items-center my-3 lg:gap-3 ">
        <h2 className=" !text-xl font-bold">Popular locations in India </h2>
        <p className=" text-sm pb-1 text-gray-700 lg:text-sm">
        choose a city for salon booking

        </p>
        <div className="flex gap-3  overflow-x-scroll ml-4 ">
          <img
            src={AhmedabadImg}
            alt="Ahmedabad"
            className=" !h-24 !w-24 object-contain shrink-0 rounded-3xl cursor-pointer"
            onClick={() => navigate("/ahmedabad/salons")}
          />
           <img
            src={Gandhinagar}
            alt="Gandhinagar"
            className=" !h-24 !w-24 object-contain shrink-0 rounded-3xl cursor-pointer"
            onClick={() => navigate("/gandhinagar/salons")}
          />
          <img
            src={BangaloreImg}
            alt="Bangalore"
            className=" !h-24 !w-24 object-contain shrink-0 rounded-3xl cursor-pointer"
            onClick={() => navigate("/bangalore/salons")}
          />
          <img
            src={MumbaiImg}
            alt="Mumbai"
            className=" !h-24 !w-24 object-contain shrink-0 rounded-3xl cursor-pointer"
            onClick={() => navigate("/mumbai/salons")}
          />
          <img
            src={DelhiImg}
            alt="Delhi"
            className=" !h-24 !w-24 object-contain shrink-0 rounded-3xl cursor-pointer mr-4"
            onClick={() => navigate("/delhi/salons")}
          />
        </div>
      </div>

      <BestSalons cities={city} />

      {/* Offer Starts------------------- */}
      {offersData?.length > 0 && (
        <div className="">
          <h2 className=" text-xl font-bold ml-[15px] pt-5 pb-3 md:ml-10 md:pt-8 ">
            Offers For You
          </h2>
          <div className="max-w-full mx-[15px] md:ml-10">
            <Swiper
              spaceBetween="20"
              slidesPerView={
                window.innerWidth < 640 ? 1 : window.innerWidth < 1024 ? 2 : 3
              }
              navigation
              autoplay={{ delay: 5000, disableOnInteraction: false }}
            >
              {offersData?.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="last:mr-[15px] md:last:mr-10 clg:last:mr-[calc((100vw-1360px)/2)]"
                >
                  <img
                    src={item?.image}
                    className="w-auto object-contain"
                    alt=""
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}

      {featureThisWeekData?.length > 0 && (
        <div className="mt-3">
          <h2 className=" text-xl font-bold ml-[15px] pt-5 pb-3 md:ml-10 md:pt-8 ">
            Featured This Week
          </h2>
          <div className="max-w-full ml-[15px] md:ml-10">
            <Swiper spaceBetween="20" slidesPerView={"auto"} navigation>
              {featureThisWeekData?.map((item, index) => {
                const { offerFormat, offerContent } = getOfferFormat(
                  item?.custom_offer_tag,
                  item?.salon_offer_tag
                );
                return (
                  <SwiperSlide
                    key={index}
                    className="last:mr-[15px] md:last:mr-10  !w-[300px] !h-[350px] rounded-3xl"
                    onClick={() =>
                      navigate(
                        `/${encodeURIComponent(
                          item?.salon_city
                        )}/${encodeURIComponent(
                          item?.salon_area
                        )}/salons/${encodeURIComponent(item?.salon_slug)}`
                      )
                    }
                  >
                    <div className="relative h-full w-full">
                      <img
                        src={item?.salon_image}
                        className="w-full h-full object-cover rounded-3xl"
                        alt=""
                      />
                      <div className="absolute bottom-0 left-0 right-0  text-white p-4 pt-5 rounded-b-3xl bg-gradient-to-t from-[#000000] to-[#00000000] z-10 flex gap-1 items-center justify-between">
                        <div className="">
                          <div className=" text-xl font-bold leading-6 pb-[10px] -ml-[5px]">
                            {offerFormat === "fixed-amount" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span className="custom-offer-tag-text1">
                                  {offerContent.split(" ")[0].toUpperCase()}
                                </span>
                                <span className="custom-offer-tag-text2">
                                  {" "}
                                  {offerContent
                                    .split(" ")
                                    .slice(1)
                                    .join(" ")
                                    .toUpperCase()}
                                </span>
                              </div>
                            ) : offerFormat === "percentage" ||
                              offerFormat === "fixed-amount-service" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span className="custom-offer-tag-text1">
                                  {offerContent.split(" ")[0].toUpperCase()}
                                </span>
                                <span className="custom-offer-tag-text2">
                                  {" "}
                                  {offerContent.split(" ")[1].toUpperCase()?.replace("RS","₹")} OFF
                                </span>
                                <span className="custom-offer-tag-text3 line-clamp-1">
                                  {" "}
                                  ON{" "}
                                  {offerContent
                                    .split(" ")
                                    .slice(4)
                                    .join(" ")
                                    .toUpperCase()}
                                </span>
                              </div>
                            ) : offerFormat === "service-price" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span className="custom-offer-tag-text1 line-clamp-1">
                                  {offerContent
                                    .split(" ")
                                    .slice(0, -2)
                                    .join(" ")
                                    .toUpperCase()}
                                </span>{" "}
                                <span className="custom-offer-tag-text2">
                                  {offerContent
                                    .split(" ")
                                    .slice(-2)[0]
                                    .toUpperCase()}{" "}
                                  {offerContent
                                    .split(" ")
                                    .slice(-1)[0]
                                    .toUpperCase()}
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className=" text-base font-medium line-clamp-1">
                            {item?.salon_name}
                          </div>
                          <div className=" text-xs font-light line-clamp-1">
                            {item?.salon_area} , {item?.salon_city}
                          </div>
                        </div>
                        <div className="">
                          <img src={right_arrow} alt="" />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      )}

      <PopularLocations />
      {/* Top Areas By City */}

      <div className="homepage-main-title" id="start">
        Popular locations in
        <span>
          <img src={flagImg} alt="indian flag" />
        </span>
        Bharat
      </div>
      <div className="homepage-disc">
        Ready to glam up in the hottest cities across India? We have got you
        covered with our curated list of top-notch salons in the most happening
        spots!
        <br />
        Whether you're in the vibrant streets of Mumbai, soaking in the culture
        of Delhi, embracing the tech-savvy vibes of Bangalore, savoring the
        culinary delights of Ahmedabad, or enjoying the serene beauty of
        Gandhinagar, Trakky brings the best salons in India right to your
        service.
      </div>

      {/* popular city */}

      <div className="main-area-links">
        {city?.map((item) => {
          return (
            <Link
              to={`/${encodeURIComponent(item?.name.toLowerCase())}/salons`}
            >
              <div className="area-group-item" key={item.id}>
                {item.name}
                <span>
                  <img src={arrowSvg} alt="arrow" />
                </span>
              </div>
            </Link>
          );
        })}
      </div>

      {/* popular area */}

      {city &&
        city.map((item) => {
          return (
            <>
              {item?.area_names && item?.area_names.length > 0 && (
                <>
                  <div className="homepage-main-title">{item.name}</div>
                  <div className="main-area-links">
                    {item?.area_names
                      .slice(
                        0,
                        expandedCities[item.id]
                          ? item.area_names.length
                          : Math.min(displayNo, item.area_names.length)
                      )
                      .map((area, index) => {
                        return (
                          <Link
                            to={`/${encodeURIComponent(
                              item.name.toLowerCase()
                            )}/salons/${encodeURIComponent(
                              area.toLowerCase()
                            )}`}
                          >
                            <div className="area-group-item" key={index}>
                              {area}
                              <span>
                                <img src={arrowSvg} alt="arrow" />
                              </span>
                            </div>
                          </Link>
                        );
                      })}
                  </div>
                  {!expandedCities[item.id] &&
                    item?.area_names.length > displayNo && (
                      <button
                        className="show-more-button-area"
                        onClick={() => handleShowMoreArea(item.id)}
                      >
                        Show More
                      </button>
                    )}
                  {expandedCities[item.id] && (
                    <button
                      className="show-more-button-area"
                      onClick={() =>
                        setExpandedCities((prev) => ({
                          ...prev,
                          [item.id]: false,
                        }))
                      }
                    >
                      Show Less
                    </button>
                  )}
                </>
              )}
            </>
          );
        })}

      <FAQ />
      <AboutUs />
      <FooterN city={"ahmedabad"} />
    </div>
  );
};

export default MainHome;

const AboutUs = () => {
  return (
    <div className="about-us-section">
      <h1 className="px-4">About Us</h1>
      <div className="about-us-content">
        <p className="px-4 ">
          Welcome to Trakky, your one-stop destination for all things beauty and
          relaxation. We are more than simply an online booking platform; we are
          your dependable partner in looking and feeling your best.
        </p>
        <p className="px-4 ">
          At Trakky, we realize the value of self-care and its significance in
          improving your overall well-being. That is why we have designed a
          simple and convenient method for you to obtain the top salon and spa
          services in your area.
        </p>
        <p className="px-4 ">
          Our purpose is to inspire you to prioritize self-care and incorporate
          it into your daily routine. We believe that everyone has the right to
          look and feel their best, and we're here to help.
        </p>
      </div>
    </div>
  );
};
