import React from 'react'
import './Termsofuse.css'
const TermsOfUse = () => {
  return (
    <div className='Termsofuse_main'>
      <p>
        These terms of use (the "Terms of Use") govern your use of our website,
        www.trakky.in (the "Website"). The Website is referred to as the
        "Platform". Please read these Terms of Use carefully before you use our
        services. If you do not agree to these Terms of Use, you may not use the
        services on the Platform. By using the Platform, you shall be
        contracting with Trakky and you signify your acceptance to the Terms of
        Use and other Trakky policies (including but not limited to the
        Cancellation & Refund Policy, Privacy Policy and Take Down Policy) as
        posted on the Platform from time to time, which take effect on the date
        on which you use the Services, and create a legally binding arrangement
        to abide by the same. Trakky enables transactions between participant
        Salon Marchant /Manager and users, dealing in Salon booking (" Platform
        Services"). The users (" user/s") can choose and book the services from
        Salon and Trakky enables them to call or massage, or book the Salon or
        user to ensure delivery of such Trakky values & services at select
        localities of serviceable cities across India. The Platform Services
        such as, phone calls, massaging, emailing are collectively referred to
        as “Trakky Services.”
      </p>
      <h3>Amendments</h3>
      <p>
        These Terms of Use are subject to modification at any time. We reserve
        the right to modify or change these Terms of Use and other Trakky
        policies at any time by posting changes on the Platform, and you shall
        be liable to update yourself of such changes, if any, by accessing the
        changes on the Platform. You shall, at all times, be responsible for
        regularly reviewing the Terms of Use and the other Trakky policies and
        note the changes made on the Platform. Your continued usage of the
        services after any change is posted constitutes your acceptance of the
        amended Terms of Use and other Trakky policies. As long as you comply
        with these Terms of Use. Trakky grants you a personal, non-exclusive,
        non- transferable, limited privilege to access, enter, and use the
        Platform. By accepting these Terms of Use, you also accept and agree to
        be bound by the other terms and conditions and Trakky policies
        (including but not limited to Cancellation & Refund Policy. Privacy
        Policy and Notice and Take Down Policy) as may be posted on the Platform
        from time to time.
      </p>
      <h3>Use of Platform and Services</h3>
      <p>
        All commercial/contractual terms are offered by and agreed to between
        Buyers and Merchants alone. The commercial/contractual terms include
        without limitation price, taxes, shipping costs, payment methods,
        payment terms, date, period. warranties related to beauty products and
        services and after sales services related to beauty products and
        services. Trakky does not have any control or does not determine or
        advise or in any way involve itself in the offering or acceptance of
        such commercial/ contractual terms between the Buyers and Merchants.
        Trakky may, however, offer support services to Merchants in respect to
        order fulfilment, payment collection, call centre, and other services,
        pursuant to independent contracts executed by it with the Merchants.
        Trakky does not make any representation or warranty as to the
        item-specifics (such as legal title, creditworthiness, identity, etc.)
        of any of the Merchants. You are advised to independently verify the
        bona fides of any particular Merchant that you choose to deal with on
        the Platform and use your best judgment in that behalf. All Merchant
        offers and third-party offers are subject to respective party terms and
        conditions. Trakky takes no responsibility for such offers. Trakky
        neither make any representation or warranty as to specifics (such as
        quality, value, sale ability, etc.) of the cosmetics or other beauty
        products or services proposed to be sold or offered to be sold or
        purchased on the Platform nor does implicitly or explicitly support or
        endorse the sale or purchase of any beauty products or services on the
        Platform. Trakky accepts no liability for any errors or omissions,
        whether on behalf of itself or third parties. Trakky is not responsible
        for any non-performance or breach of any contract entered into between
        Buyers and Merchants on the Platform. Trakky cannot and does not
        guarantee that the concerned Buyers and/or Merchants will perform any
        transaction concluded on the Platform. Trakky is not responsible for
        unsatisfactory or non-performance of Salon services and their beauty
        products.
      </p>
      <p>
        Trakky is operating an online marketplace and assumes the role of
        facilitator, and does not at any point of time during any transaction
        between Buyer and Merchant on the Platform come into or take possession
        of any of the beauty products or salon services offered by Merchant. At
        no time shall Trakky hold any right. title or interest over the products
        nor shall Trakky have any obligations or liabilities in respect of such
        contract entered into between Buyer and Merchant.
      </p>
      <p>
        Trakky is only providing a platform for communication between buyer and
        Merchant during the salon visit of Buyer and it is agreed that the
        contract for sale of any of the beauty products or salon services shall
        be a strictly bipartite contract between the Merchant and the Buyer. In
        case of complaints from the Buyer pertaining to Salon service or their
        product efficacy, quality, or any other such issues, Trakky shall notify
        the same to Merchant and shall also redirect the Buyer to the consumer
        call centre of the Merchant. The Merchant shall be liable for redressing
        Buyer complaints. In the event you raise any complaint about any
        Merchant accessed using our Platform, we shall assist you to the best of
        our abilities by providing relevant information to you, such as details
        of the Merchant and the specific Order to which the complaint relates,
        to enable a satisfactory resolution of the complaint.
      </p>
      <p>
        Please note that there could be risks in dealing with underage persons
        or people acting under false pretence.
      </p>
      <h3>Trakky Use of the Website (Android and iOS)</h3>
      <p>
        You agree, undertake and confirm that your use of Platform shall be
        strictly governed by the following binding principles as You shall not
        host display, upload, download, modify, publish, transmit, update or
        share any information that:
      </p>
      <p>belongs to another person and which you do not have any right to;</p>
      <p>
        is grossly harmful, harassing, blasphemous, defamatory, obscene,
        pornographic, pedophilic, libelous, slanderous, criminally inciting or
        invasive of another's privacy, hateful, or racially, ethnically
        objectionable, disparaging, relating or encouraging money laundering or
        gambling, or otherwise unlawful in any manner whatsoever:
      </p>
      <p>
        <ul className='Termsofuse_unordered'>
          <li>is misleading or misrepresentative in any way.</li>
          <li>
            is patently offensive to the online community, such as sexually
            explicit content, or content that promotes obscenity, pedophilia,
            racism, bigotry, hatred or physical harm of any kind against any
            group or individual:
          </li>
          <li>harasses or advocates harassment of another person:</li>
          <li>
            involves the transmission of "junk mail", "chain letters.” or
            unsolicited mass mailing or "spamming.”
          </li>
          <li>
            promotes illegal activities or conduct that is abusive, threatening,
            obscene, defamatory or libelous;
          </li>
          <li>
            infringes upon or violates any third party's rights [including, but
            not limited to, intellectual property rights, rights of privacy
            (including without limitation unauthorized disclosure of a person's
            name, email address, physical address or phone number) or rights of
            publicity]:
          </li>
          <li>
            promotes an illegal or unauthorized copy of another person's
            copyrighted work (see "copyright complaint" below for instructions
            on how to lodge a complaint about uploaded copyrighted material),
            such as providing pirated computer programs or links to them,
            providing information to circumvent manufacture-installed
            copy-protect devices, or providing pirated music or links to pirated
            music files;
          </li>
          <li>
            contains restricted or password-only access pages, or hidden pages
            or images (those not linked to or from another accessible page);
          </li>
          <li>
            tries to gain unauthorized access or exceeds the scope of authorized
            access to the Platform or to profiles, blogs, communities, account
            information, bulletins, friend request, or other areas of the
            Platform or solicits passwords or personal identifying information
            for commercial unlawful purposes from other users:
          </li>
          <li>
            engages in commercial activities and/or sales without our prior
            written consent such as contests, sweepstakes. barter, advertising
            and pyramid schemes, or the buying or selling of products related to
            the Platform. Throughout these Terms of Use, Trakky's prior written
            consent means a communication coming from Trakky's Legal Department.
            specifically, in response to your request, and expressly addressing
            and allowing the activity or conduct for which you seek
            authorization:
          </li>
          <li>
            solicits gambling or engages in any gambling activity which is or
            could be construed as being illegal;
          </li>
          <li>
            interferes with another user's use and enjoyment of the Platform or
            any third party's user and enjoyment of similar services:
          </li>
          <li>
            refers to any website or URL that, in our sole discretion, contains
            material that is inappropriate for the Platform or any other
            website, contains content that would be prohibited or violates the
            letter or spirit of these Terms of Use:
          </li>
          <li>harm minors in any way:</li>
          <li>
            infringes any patent, trademark, copyright or other intellectual
            property rights or third party's trade secrets or rights of
            publicity or privacy or shall not be fraudulent or involve the sale
            of counterfeit or stolen products:
          </li>
          <li>violates any law for the time being in force;</li>
          <li>
            deceives or misleads the addressee/users about the origin of such
            messages or communicates any information which is grossly offensive
            or menacing in nature;
          </li>
          <li>impersonate another person;</li>
          <li>
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource; or contains any trojan horses, worms, time
            bombs, cancelbots, easter eggs or other computer programming
            routines that may damage, detrimentally interfere with, diminish
            value of, surreptitiously intercept or expropriate any system, data
            or personal information:
          </li>
          <li>
            threatens the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any criminal offence or
            prevents investigation of any offence or is insulting any other
            nation;
          </li>
          <li>is false, inaccurate or misleading:</li>
          <li>
            directly or indirectly, offers, attempts to offer, trades or
            attempts to trade in any item, the dealing of which is prohibited or
            restricted in any manner under the provisions of any applicable law,
            rule, regulation or guideline for the time being in force; or
          </li>
          <li>
            creates liability for us or causes us to lose (in whole or in part)
            the services of our internet service provider or other suppliers.
          </li>
        </ul>
      </p>
      <h3>Customer Reviews</h3>
      <ol className='Termsofuse_order'>
        <li>
          Reviews or ratings provided by customers for Trakky partners do not
          necessarily reflect the opinion of Trakky. Trakky receives multiple
          reviews or ratings from customers, which represent the individual
          opinions of those customers. Trakky serves as a neutral platform,
          facilitating communication between customers/reviewers and partner
          owners/representatives through the partner's business page.
          Advertisements published on the Trakky Platform are independent of the
          reviews received by advertisers.
        </li>
        <li>
          Trakky maintains neutrality and does not arbitrate disputes. If a
          partner believes that a review is untrue, the recommended course of
          action for the partner representative is to contact the reviewer
          directly or post a public response to address any misunderstandings.
          If a partner believes that a customer's review violates Trakky's
          policies, the partner may notify Trakky by emailing
          customercare@trakky.in and bringing the violation to our attention.
          Trakky reserves the right to remove reviews at its sole discretion if
          they violate the Terms, content guidelines, policies, or are otherwise
          detrimental to the services provided.
        </li>
      </ol>
      <p>
        Feel free to share your experience and opinions about Trakky partners.
        Your feedback helps us maintain a high standard of service and ensures
        transparency for all users.
      </p>
      <h3>Account Registration or use of the Platform</h3>
      <p>
        You may access the Platform by registering to create an account ("
        Trakky Account") and become a member (" Membership"); or (c) you can
        also register to join by logging into your account with certain
        third-party social networking sites ("SNS") (including, but not limited
        to, Facebook): each such account, a " Third Party Account", via our
        Platform, as described below. The Membership is limited for the purpose
        and are subject to the terms, and strictly not transferable. As part of
        the functionality of the Platform services, you may link your Trakky
        Account with Third Party Accounts, by either:
      </p>
      <p>
        <ul className='Termsofuse_unordered'>
          <li>
            providing your Third-Party Account login information to us through
            the Platform; or
          </li>
          <li>
            allowing us to access your Third-Party Account as is permitted under
            the applicable terms and conditions that govern your use of each
            Third-Party Account.
          </li>
        </ul>
      </p>
      <p>
        You represent that you are entitled to disclose your Third-Party Account
        login information to us and/or grant us access to your Third-Party
        Account (including, but not limited to, for use for the purposes
        described herein), without breach by you of any of the terms and
        conditions that govern your use of the applicable Third-Party Account
        and without obligating us to pay any fees or making us subject to any
        usage limitations imposed by such third-party service providers. By
        granting us access to any Third-Party Accounts, you understand that we
        will access, make available and store (if applicable) any content or
        information that you have provided to and stored in your Third-Party
        Account (" SNS Content") so that it is available on and through the
        Platform via your Trakky Account. Unless otherwise specified in these
        Terms of Use, all SNS Content, if any, will be considered to be your
        content for all purposes of these Terms of Use.
      </p>
      <p>
        Depending on the Third-Party Accounts, you choose. and subject to the
        privacy settings that you have set in such Third-Party Accounts,
        personally identifiable information that you post to your Third-Party
        Accounts will be available on and through your Trakky Account on the
        Platform. Please note that if a Third-Party Account or associated
        service becomes unavailable or our access to such Third-Party Account is
        terminated by the third-party service provider, then SNS Content will no
        longer be available on and through the Platform. We will create your
        Trakky Account for your use of the Platform services based upon the
        personal information you provide to us or that we obtain via an SNS, as
        described above. Trakky Members will be required to share certain
        personal information with Trakky including their name, phone number,
        email address, payment details so that we can connect with you when its
        required in terms of booking, your confirmation, cross-checking for our
        better service. We can connect with you via text massage, emailing and
        call to server our best service you not have any objection for this and
        you may only have one Trakky Account and not permitted to create
        multiple accounts. If found, you having multiple accounts, Trakky
        reserves right to suspend such multiple account without being liable for
        any compensation. You agree to provide accurate, current and complete
        information during the registration process and to update such
        information to keep it accurate, current and complete. We reserve the
        right to suspend or terminate your Trakky Account and your access to the
        Services (i) if any information provided during the registration process
        or thereafter proves to be inaccurate, not current or incomplete; (ii)
        if it is believed that your actions may cause legal liability for you,
        other users or us; and/or (iii) if you are found to be non- compliant
        with the Terms of Use. You are responsible for safeguarding your
        password. You agree that you will not disclose your password to any
        third party and that you will take sole responsibility for any
        activities or actions under your Trakky Account, whether or not you have
        authorized such activities or actions. You will immediately notify us of
        any unauthorized use of your Trakky Account.
      </p>
      <p>
        Goods and services purchased from the Platform are intended for your
        personal use and you represent that the same are not for resale or you
        are not acting as an agent for other parties.
      </p>
      <h3>Intellectual property</h3>
      <p>
        We are either the owners of intellectual property rights or have the
        non-exclusive, worldwide, perpetual, irrevocable, royalty free,
        sub-licensable (through multiple tiers) right to exercise the
        intellectual property, on the Platform, and in the material published on
        it. You may print off one copy, and may download extracts, of any
        page(s) from the Platform for your personal reference and you may draw
        the attention of others within your organization to material available
        on the Platform. You must not modify the paper or digital copies of any
        materials you have printed off or downloaded in any way, and you must
        not use any photographs, illustrations, audio / video sequences or any
        graphics separately from any accompanying text. You must not use any
        part of the materials on the Platform for commercial purposes without
        obtaining a license to do so from us or our licensors. If you print off,
        copy or download any part of the Platform in breach of these Terms of
        Use, your right to use the Platform will cease immediately and you must,
        at our option, return or destroy any copies of the materials you have
        made.
      </p>
      <h3>Contact Us</h3>
      <p>
        Please contact us for any questions or comments (including all inquiries
        unrelated to copyright infringement) regarding this Platform.
      </p>
      <p>Phone: - <a href="tel:93283 82710" className='Termsofuse_number'>+91 93283 82710</a></p>
      <p>Email: - customercare@trakky.in </p>
    </div>
  )
}

export default TermsOfUse