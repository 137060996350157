import React from "react";
import { capitalizeAndFormat } from "../../../functions/generalFun";
import { Link, useParams } from "react-router-dom";
import top_rated_image from "../../../../Assets/images/category/top_rated_image.jpg";
import bridal_image from "../../../../Assets/images/category/bridal_image.jpg";
import unisex_image from "../../../../Assets/images/category/unisex_image.jpg";
import kids_image from "../../../../Assets/images/category/kids_special.jpg";
import female_image from "../../../../Assets/images/category/female_beauty_paurlor.jpg";
import male_image from "../../../../Assets/images/category/male_images.jpg";
import academy_image from "../../../../Assets/images/category/academy_image.jpg";
import makeup_image from "../../../../Assets/images/category/makeup.jpg";

function CheckoutBestCategory() {

  const params = useParams();

  const categories = [
    // "Top Salons",
    // "Bridal Salons",
    // "Unisex Salons",
    // "Kids Special Salons",
    // "Female Beauty Parlours",
    // "Male Salons",
    // "Salon Academy",
    // "Makeup Salons",
    {
      name: "Top Salons",
      image: top_rated_image,
      // link: `/${params?.city}/list/?salon_category=top_rated`
      link: `/${params?.city}/topratedsalons`
    },
    {
      name: "Bridal Salons",
      image: bridal_image,
      // link: `/${params?.city}/list/?salon_category=bridal`
      link: `/${params?.city}/bridalsalons`
    },
    {
      name: "Unisex Salons",
      image: unisex_image,
      // link: `/${params?.city}/list/?salon_category=unisex_salon`
      link: `/${params?.city}/unisexsalons`
    },
    {
      name: "Kids Special Salons",
      image: kids_image,
      // link: `/${params?.city}/list/?salon_category=kids_special_salons`
      link: `/${params?.city}/kidsspecialsalons`
    },
    {
      name: "Female Beauty Parlours",
      image: female_image,
      // link: `/${params?.city}/list/?salon_category=female_beauty_parlour`
      link: `/${params?.city}/femalebeautyparlour`
    },
    {
      name: "Male Salons",
      image: male_image,
      // link: `/${params?.city}/list/?salon_category=male_salons`
      link: `/${params?.city}/malesalons`
    },
    {
      name: "Salon Academy",
      image: academy_image,
      // link: `/${params?.city}/list/?salon_category=salon_academy`
      link: `/${params?.city}/academysalons`
    },
    {
      name: "Makeup Salons",
      image: makeup_image,
      // link: `/${params?.city}/list/?salon_category=makeup`
      link: `/${params?.city}/makeupsalons`
    }
    ]

  return (
    <div className="mt-4 lg:mt-10">
      <div className="mx-4 flex justify-between md:mx-10">
        <h1 className="text-xl font-semibold">
          Checkout best salon categories
        </h1>
      </div>
      <div className="ml-4 py-4 flex gap-5 overflow-x-scroll snap-x snap-mandatory md:ml-10">
        {categories.map((category, index) => (
          <Link to={category.link} className="flex relative flex-col gap-4 snap-start last:mr-4 rounded-2xl shrink-0 h-44 w-36 bg-gray-200">
            <div className=" absolute bottom-2 left-2 text-sm font-semibold text-white">
              {
                category.name + ` in ${capitalizeAndFormat(params?.city)}`
              }
            </div>
            <img src={category.image} className="h-full w-full rounded-2xl" alt="" />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default CheckoutBestCategory;
